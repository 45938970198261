import React from "react";
import { Helmet } from "react-helmet";

export default function MessageError() {
  return (
    <div id="notfound">
      <Helmet>
        <title>404 Page Not Found</title>
      </Helmet>
      <div className="notfound">
        <h2>Our apologies</h2>
        <p>
          but the redemption window for this event has closed. If you have any
          questions, please contact your direct supervisor at CITY Furniture.
        </p>
      </div>
    </div>
  );
}
